import axios from "axios";
import { getFromLocal, saveToLocal } from "@/utils.js";

import ToolBar from "@/components/page-toolbar/page-toolbar.vue";
import PageFooter from "@/components/page-footer/page-footer.vue";

import trackWindow from "@/trackWindow.js";

export default {
  name: "App",
  components: {
    ToolBar,
    PageFooter,
  },
  mixins: [trackWindow],
  props: {},
  data() {
    return {
      orgSettings: null,
      utilSettings: null,
      hasIssue: false,
      hasIssueMessage: "",
    };
  },
  computed: {
    isLoading: function () {
      return this.orgSettings == null || this.utilSettings == null;
    },
  },
  created() {
    //set default base url for axios
    if (process.env.NODE_ENV == "development")
      axios.defaults.baseURL = `https://${window.location.hostname}:44358`;

    this.$root.$on("notify", (msg, type = 1) => this.notify(msg, type));
    this.$root.$on("errorHandling", this.errorHandling);
    this.$root.$on("setLocale", (locale) => this.setLocale(locale));

    this.getOrganisationSettings();
    this.getSettings();

    //locale
    let loc = getFromLocal("locale");
    if (!loc) {
      loc = "en";
    }
    this.setLocale(loc);
  },
  mounted() {},
  methods: {
    notify(msg, type) {
      let tp = type;
      if (typeof type == "string") {
        tp = type;
      } else if (typeof type == "number")
        switch (type) {
          default:
          case 1:
            tp = "info";
            break;
          case 2:
            tp = "success";
            break;
          case 3:
            tp = "warn";
            break;
          case 4:
            tp = "error";
            break;
        }

      this.$notify({ text: msg, type: tp });
      //console.log("---", msg);
    },
    errorHandling(err, appHasIssue = false) {
      //console.log("err", err);

      let msg;
      if (err) {
        if (err.message) msg = err.message;

        if (err.response && err.response.data) msg += `\n${err.response.data}`;
      }

      if (!msg) msg = "We Encountered an unknown error";

      this.notify(msg, 4);

      this.hasIssue = appHasIssue;
      this.hasIssueMessage = appHasIssue ? msg : "";
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      saveToLocal("locale", locale);
      this.$store.commit("setLocale", locale);
    },
    getOrganisationSettings() {
      axios
        .post("/Utility/GetOrganisationSettings")
        .then((response) => {
          if (response.data.data.status == 0) {
            this.orgSettings = response.data.settings;
            this.$store.commit("setOrgSettings", response.data.settings);
          }
        })
        .catch((err) => {
          this.errorHandling(err, true);
        });
    },
    getSettings() {
      axios
        .post("/Utility/GetSettings")
        .then((response) => {
          if (response.data.data.status == 0) {
            this.utilSettings = response.data.value;
            this.$store.commit("setUtilitySettings", response.data.value);
          }
        })
        .catch((err) => this.errorHandling(err, true));
    },
  },
};

import Vue from "vue";
import App from "./App/App.vue";
import store from "./store";

///////////////////////////////////////////////////////
///////////////Vue Material Imports////////////////////

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

Vue.use(VueMaterial);
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////

import Notifications from "vue-notification";
Vue.use(Notifications);

///////////////////////////////////////////////////////
//////////////////////Vue i18n/////////////////////////
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

var enJSON = require("./i18n/en.json");
var elJSON = require("./i18n/el.json");
var arJSON = require("./i18n/ar.json");

var messages = {
  en: enJSON,
  el: elJSON,
  ar: arJSON,
};

///////////////////////////////////////////////////////
//////////////////Vue router///////////////////////////
// import VueRouter from "vue-router";
// Vue.use(VueRouter);
import router from "./router.js";
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n: new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: messages,
  }),
}).$mount("#app");

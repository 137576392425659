import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /* Routes for public user */
    {
      name: "support",
      path: "/support",
      component: require("./Pages/contact-form/contact-form.vue").default,
      meta: { requiresAuth: false },
    },
    {
      name: "support",
      path: "/",
      component: require("./Pages/contact-form/contact-form.vue").default,
      meta: { requiresAuth: false },
    },
    {
      name: "404",
      path: "*",
      component: require("./Pages/noPageFound.vue").default,
      meta: { requiresAuth: false },
    },
  ],
});

export function getFromLocal(location) {
  return JSON.parse(localStorage.getItem(location));
}

export function saveToLocal(location, item) {
  localStorage.setItem(location, JSON.stringify(item));
}

export function validateEmailPhone(value, regExpr) {
  value = value.trim();

  return validatePhoneNumber(value, regExpr) || validateEmail(value);
}

export function validateEmail(email) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true;
  else return false;
}

export function validatePhoneNumber(phoneNo, expr) {
  if (phoneNo == null || phoneNo == "" || !isNumeric(phoneNo)) return false;

  if (!expr) return true;

  const regex = new RegExp(expr);

  return regex.test(phoneNo);
}

export function isNumeric(str) {
  if (typeof str != "string") return false;

  return /^\d+$/.test(str);
}

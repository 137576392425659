export default {
  name: "Footer",
  components: {},
  props: {},
  data() {
    return {
      settings: null,
      brandingColor: "",
      brandingAccentColor: "",
      showFullText: true,
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.settings = await this.$store.getters.getOrgSettings;

      if (this.settings && this.settings.brandingColor)
        this.brandingColor =
          "background-color: " + this.settings.brandingColor + "!important;";

      if (this.settings && this.settings.brandingAccentColor)
        this.brandingAccentColor =
          "color: " + this.settings.brandingAccentColor + "!important;";
    },

    getCopyrightMessage() {
      var year = new Date();
      return "Copyright Hydro-Comp Enterprises Ltd, " + year.getFullYear();
    },
  },
};

import { render, staticRenderFns } from "./page-toolbar.vue?vue&type=template&id=2218a0ca"
import script from "./page-toolbar.js?vue&type=script&lang=js&external"
export * from "./page-toolbar.js?vue&type=script&lang=js&external"
import style0 from "./page-toolbar.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
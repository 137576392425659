import axios from "axios";
import { validateEmail, validatePhoneNumber } from "@/utils.js";
import * as files from "@/fileUtils.js";

export default {
  name: "ContactForm",
  props: {},
  data() {
    return {
      messages: [],
      msgError: {
        name: false,
        email: false,
        phone: false,
        userMessage: false,
      },
      profileDetails: {},
      isLoading: false,

      name: "",
      email: "",
      phone: "",
      userMessage: "",
      termsAndConditions: "",

      validateOTP: false,
      emailOtpCode: "",
      phoneOtpCode: "",
      isEmailOtpCodeValid: false,
      isPhoneOtpCodeValid: false,
      informUser: false,

      contactUsLocations: [],
      contactUsLocationsMessages: [],
    };
  },
  computed: {
    isSendDisabled: function () {
      return (
        !this.userMessage ||
        this.userMessage.length == 0 ||
        !this.name ||
        this.name.length == 0 ||
        ((!this.email || this.email.length == 0) &&
          (!this.phone || this.phone.length == 0))
      );
    },
  },
  created() {
    this.getPortalLicenceAgreement();
    this.getMessages();
  },
  mounted() {},
  methods: {
    getPortalLicenceAgreement() {
      axios
        .post("/Utility/GetPortalLicenceAgreement", {
          Value: this.$store.getters.getLocale,
        })
        .then((response) => {
          if (response.data.data.status == 0) {
            this.termsAndConditions = response.data.settings.text ?? "";
          }
        })
        .catch((err) => this.errorHandling(err));
    },
    getMessages() {
      let param = { lang: this.$store.getters.getLocale, TextIds: ["94"] };

      axios
        .post("Utility/GetMessages", param)
        .then((response) => {
          if (
            response.status == 200 &&
            response.data &&
            response.data.length > 0
          ) {
            //console.log("resp", response.data);
            if (response.data && response.data.length > 0)
              if (
                response.data &&
                response.data.length > 0 &&
                response.data[0] &&
                response.data[0].text
              )
                this.contactUsLocations = JSON.parse(response.data[0].text);
          }
        })
        .catch((err) => this.errorHandling(err));
    },
    getTelephone(item) {
      return "tel:" + item.replace(/[^0-9]/g, "");
    },

    downloadFile(type) {
      let id;
      if (type == "appGuide") id = 1;
      if (type == "appMapGuide") id = 2;

      axios
        .post(
          "/Utility/DownloadLocalizedFile",
          { Value: id },
          { responseType: "blob" }
        )
        .then((res) => files.getFile(res, type))
        .then((link) => {
          link.click();
        })
        .catch((err) => {
          this.$root.$emit("notify", err, 4);
        });
    },
    showMessage(msg, type) {
      if (type == 3 && type == 4) this.message = msg;
      else this.message = "";

      this.$root.$emit("notify", msg, type);
    },

    validate() {
      this.isLoading = true;
      this.msgError = {
        name: false,
        email: false,
        phone: false,
        userMessage: false,
      };

      let regEx;
      let utilSettings = this.$store.getters.getUtilitySettings;

      if (utilSettings && utilSettings.customerMobileNoRegExpr)
        regEx = utilSettings.customerMobileNoRegExpr;

      let phoneVal = regEx
        ? validatePhoneNumber(this.phone, regEx)
        : this.phone && this.phone.length > 0;

      let emailVal = validateEmail(this.email);

      this.msgError.name = !this.name || this.name.length == 0;

      this.msgError.userMessage =
        !this.userMessage || this.userMessage.length == 0;

      if (this.msgError.name) {
        this.showMessage(this.$t("ErrRequiredFields"), 3);
        return false;
      }

      if (this.phone == "" && this.email == "") {
        this.showMessage(this.$t("WrongPhone"), 3);
        this.showMessage(this.$t("WrongEmail"), 3);
        this.msgError.email = false;
        this.msgError.phone = false;
        return false;
      }

      if (!this.phone && this.email && !emailVal) {
        this.showMessage(this.$t("WrongEmail"), 3);
        return false;
      }

      if (!this.email && this.phone && !phoneVal) {
        this.showMessage(this.$t("WrongPhone"), 3);
        return false;
      }

      if (this.msgError.userMessage) {
        this.showMessage(this.$t("ErrRequiredFields"), 3);
        return false;
      }

      return true;
    },
    verifyPhoneNumberEmail() {
      if (!this.validate()) {
        this.isLoading = false;
        return;
      }

      axios
        .post("/Session/Create", {
          MobileNo: this.phone,
          Email: this.email,
          LanguageCode: this.$store.getters.getLocale,
        })
        .then((response) => {
          console.log("resp", response);
          if (response.status == 200) {
            this.sessionId = response.data;
            this.validateOTP = true;
            //this.sendRequest(this.sessionId);
          } else this.showMessage(this.$t("MsgSentError"), 4);
        })
        .catch(() => {
          this.showMessage(this.$t("MsgSentError"), 4);
        })
        .finally((this.isLoading = false));
    },
    async validateOtpCodes() {
      if (this.sessionId < 0) {
        this.showMessage(this.$t("OtpValidationFailed"), 4);
        return;
      }

      this.isLoading = true;

      if (this.email) await this.validateOtpCode("email");

      if (this.phone) await this.validateOtpCode("phone");

      if (this.isPhoneOtpCodeValid || this.isEmailOtpCodeValid) {
        this.showMessage(this.$t("OtpVerified"), 2);
        this.sendRequest();
      } else this.isLoading = false;
    },
    validateOtpCode(type) {
      let parameter = {
        SessionId: this.sessionId,
        OtpCode: type == "phone" ? this.phoneOtpCode : this.emailOtpCode,
        OtpCodeType: type == "phone" ? 2 : 1,
      };

      return axios
        .post("/Session/ValidateOtp", parameter)
        .then((response) => {
          if (response.status == 200 && response.data == true) {
            if (type == "phone") this.isPhoneOtpCodeValid = true;
            else if (type == "email") this.isEmailOtpCodeValid = true;
          } else {
            this.showMessage(this.$t("ErrorValidatingOTP"), 4);
            return true;
          }
        })
        .catch((e) => {
          this.showMessage(this.$t("ErrorValidatingOTP") + e, 4);
          return true;
        });
    },

    sendRequest() {
      let body = `Name: ${this.name}
      Email: ${this.email}
      Phone No.: ${this.phone}
      Message: ${this.userMessage}`;

      this.isLoading = true;

      let param = {
        lang: this.$store.getters.getLocale,
        Subject: `Contact for Support from ${this.name} (${this.email})`,
        Body: body,
        SessionId: this.sessionId,
        OtpType: "",
        OtpCode: "",
      };

      if (this.isEmailOtpCodeValid) {
        param.OtpType = 1;
        param.OtpCode = this.emailOtpCode;
      } else {
        param.OtpType = 2;
        param.OtpCode = this.phoneOtpCode;
      }

      axios
        .post("Utility/SendContactUsEmail", param)
        .then((response) => {
          if (response.status != 200)
            this.showMessage(this.$t("MsgSentError"), 4);
        })
        .catch(() => {
          this.showMessage(this.$t("MsgSentError"), 4);
        })
        .finally(() => {
          this.name = "";
          this.email = "";
          this.phone = "";
          this.userMessage = "";

          this.isLoading = false;
          this.validateOTP = false;

          this.informUserFunc();
        });
    },
    informUserFunc() {
      this.informUser = true;
      setTimeout(() => {
        this.informUser = false;
      }, 30000);
    },
    errorHandling(err) {
      this.$root.$emit("errorHandling", err);
    },
  },
};

export default {
  name: "TrackWindow",
  data: function () {
    return {
      windowWidth: null,
      windowHeight: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    setTimeout(() => {
      this.handleWindowSizeChange();
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  },
  methods: {
    handleWindowSizeChange() {
      if (window.innerWidth != this.windowWidth) {
        this.windowWidth = window.innerWidth;
        this.$root.$emit("window_width_changed", this.windowWidth);
      }

      if (this.windowHeight != window.innerHeight) {
        this.windowHeight = window.innerHeight;
        this.$root.$emit("window_height_changed", this.windowHeight);
      }

      // console.log(
      //   "windowWidth:",
      //   this.windowWidth,
      //   "windowHeight:",
      //   this.windowHeight
      // );
    },
  },
};

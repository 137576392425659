export function getFile(response, name = "File") {
  var result = document.createElement("a");
  var contentDisposition = response.headers["content-disposition"] || "";
  var filename = contentDisposition.split("filename=")[1] ?? name;
  if (filename.indexOf(";") > 0) filename = filename.split(";")[0];
  filename = filename.replace(/"/g, "");

  result.href = window.URL.createObjectURL(response.data);
  result.target = "_self";
  result.download = filename;

  //saveFile(response.data, filename);

  return result;
}

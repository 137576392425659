import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: "en",
    orgSettings: {},
  },

  mutations: {
    setLocale(state, payload) {
      state.locale = payload;
    },
    setOrgSettings(state, payload) {
      state.orgSettings = payload;
    },
    setUtilitySettings(state, payload) {
      state.utilitySettings = payload;
    },
  },

  getters: {
    getLocale(state) {
      return state.locale;
    },
    getOrgSettings(state) {
      return state.orgSettings;
    },
    getUtilitySettings(state) {
      return state.utilitySettings;
    },
  },
});
